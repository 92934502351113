import { ref, computed } from "@vue/composition-api";
import axiosIns from "@/libs/axios";

function requestLoadPartners(reqData) {
  return axiosIns.post("/compassion/admin/partners", reqData, {});
}
function requestLoadIntegrations(reqData) {
  return axiosIns.post("/compassion/admin/integrations", reqData, {});
}

export function saveNewPartner(reqData) {
  return axiosIns.post("/compassion/admin/integrations", reqData, {});
}

function requestLoadEvents(reqData) {
  return axiosIns.post("/compassion/admin/events", reqData, {});
}
export function saveNewEvent(reqData) {
  return axiosIns.post("/compassion/admin/event", reqData, {});
}

export function mapCustomer(req) {
  return axiosIns.post("/compassion/admin/partner/mapDispensaryPatient", req, {});
}

export function refreshPartnerData(req) {
  return axiosIns.post("/compassion/admin/partner/refreshFromDispensary", req, {});
}

export function savePatientContact(req) {
  return axiosIns.post("/compassion/admin/operatorSaveContact", req, {});
}

export function savePatientAddress(req) {
  return axiosIns.post("/compassion/admin/operatorSaveAddress", req, {});
}

export const partnersList = ref([]);
export const eventsList = ref([]);
export const filterBy = ref(null);
export const sortBy = ref("name");
export const sortOrder = ref("asc");
const perPage = ref(25);
const loading = ref(false);
let lastPageItem = 0;
let firstPageItem = 0;
const totalRows = ref(0);

export const setPerPage = (val) => (perPage.value = val);
export const getPerPage = computed(() => perPage.value);
export const getLoading = computed(() => loading.value);
export const getTotalRows = computed(() => totalRows.value);
export const getEventList = computed(() => eventsList.value);
export const getPartnerList = computed(() => partnersList.value);

export const lookupEvent = async (search) => {
  const reqData = {
    lastItem: 0,
    perPage: 50,
    filter: {
      search: search || null
    }
  };
  return await requestLoadEvents(reqData);
};

export const lookupIntegration = async (search) => {
  const reqData = {
    lastItem: 0,
    perPage: 50,
    filter: {
      search: search || null
    }
  };
  return await requestLoadIntegrations(reqData);
};

export const onLoadAllPartners = async ({ prevPage, nextPage, reset }) => {
  let useLast = 0;
  if (!reset) {
    if (nextPage) {
      useLast = lastPageItem;
    }
    if (prevPage) {
      useLast = firstPageItem - 1;
    }
  }
  const reqData = {
    lastItem: useLast,
    perPage: perPage.value,
    sort: sortBy.value,
    sortOrder: sortOrder.value,
    filter: {
      search: filterBy.value || null
    }
  };
  loading.value = true;
  try {
    const {
      data: { allPartners, pagination }
    } = await requestLoadPartners(reqData);
    if (allPartners) {
      partnersList.value.splice(0, partnersList.value.length);
      partnersList.value.push(...allPartners);
    } else {
      partnersList.value.splice(0, partnersList.value.length);
    }
    lastPageItem = pagination.lastItem;
    firstPageItem = pagination.firstItem;
    totalRows.value = pagination.totalRows ? pagination.totalRows : 10;
  } finally {
    loading.value = false;
  }
};

export const onLoadAllEvents = async ({ prevPage, nextPage, reset }) => {
  let useLast = 0;
  if (!reset) {
    if (nextPage) {
      useLast = lastPageItem;
    }
    if (prevPage) {
      useLast = firstPageItem - 1;
    }
  }
  const reqData = {
    lastItem: useLast,
    perPage: perPage.value,
    sort: sortBy.value,
    sortOrder: sortOrder.value,
    filter: {
      search: filterBy.value || null
    }
  };
  loading.value = true;
  try {
    const {
      data: { allEvents, pagination }
    } = await requestLoadEvents(reqData);
    if (allEvents) {
      eventsList.value.splice(0, eventsList.value.length);
      eventsList.value.push(...allEvents);
    } else {
      eventsList.value.splice(0, eventsList.value.length);
    }
    lastPageItem = pagination.lastItem;
    firstPageItem = pagination.firstItem;
    totalRows.value = pagination.totalRows ? pagination.totalRows : 10;
  } catch (err) {
    console.log("Testing", err);
    //TODO: remove after testing
    eventsList.value.splice(0, eventsList.value.length);
    eventsList.value.push({ eventName: "SPARC event", eventDate: "2023-09-12", notes: "Sweetleaf/SPARC donation event" });

    console.log("Testing2", eventsList);
  } finally {
    loading.value = false;
  }
};
